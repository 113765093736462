import React from 'react'

class PurchaseForm extends React.Component {

  render = () => {
    return (
      <>
        <h2 className="header01">ご注文申し込みフォーム</h2>
        <section className="contents_block narrow mb50">
          <div className="form_block">
            <div className="form_head">
              <span className="form_require">お名前</span>
            </div>
            <div className="form_body">
              <div className="form_body_parent">
                <div className="form_body_child">
                  姓<input type="text" className="" name="LastNameKanji" value={this.props.orderForm.LastNameKanji} onChange={this.props.handleInputChange} />
                </div>
                <div className="form_body_child">
                  名<input type="text" className="" name="FirstNameKanji" value={this.props.orderForm.FirstNameKanji} onChange={this.props.handleInputChange} />
                </div>
              </div>
              <div className="err"> {this.props.orderForm.ErrorLabelTexts.NameKanji} </div>
            </div>
          </div>

          <div className="form_block">
            <div className="form_head">
              <span className="form_require">お名前（カナ）</span>
            </div>
            <div className="form_body">
              <div className="form_body_parent">
                <div className="form_body_child">
                  姓（カナ）<input type="text" className="" name="LastNameKatakana" value={this.props.orderForm.LastNameKatakana} onChange={this.props.handleInputChange} />
                </div>
                <div className="form_body_child">
                  名（カナ）<input type="text" className="" name="FirstNameKatakana" value={this.props.orderForm.FirstNameKatakana} onChange={this.props.handleInputChange} />
                </div>
              </div>
              <div className="err"> {this.props.orderForm.ErrorLabelTexts.NameKatakana} </div>
            </div>
          </div>

          <div className="form_block">
            <div className="form_head">
              <span className="form_require">メールアドレス</span>
            </div>
            <div className="form_body">
              <input type="text" className="" name="MailAddress" value={this.props.orderForm.MailAddress} onChange={this.props.handleInputChange} />
              <div className="err"> {this.props.orderForm.ErrorLabelTexts.MailAddress} </div>
            </div>
          </div>

          <div className="form_block mb80">
            <div className="form_head">
              <span className="form_require">メールアドレス（確認）</span>
            </div>
            <div className="form_body">
              <input type="text" className="" name="MailAddressConfirmation" value={this.props.orderForm.MailAddressConfirmation} onChange={this.props.handleInputChange} />
              <div className="err"> {this.props.orderForm.ErrorLabelTexts.MailAddressConfirmation} </div>
              <div className="err"> {this.props.orderForm.ErrorLabelTexts.MailAddressInconsistency} </div>
            </div>
          </div>

          <div className="form_block">
            <div className="form_head">
              <span className="form_require">書籍の送付先</span>
            </div>
            <div className="form_body">
              <div className="form_item"><label><input type="radio" className="" name="SendingAddress" value="勤務先" onChange={this.props.handleInputChange} checked={this.props.orderForm.SendingAddress === "勤務先"} />勤務先</label></div>
              <div className="form_item"><label><input type="radio" className="" name="SendingAddress" value="それ以外" onChange={this.props.handleInputChange} checked={this.props.orderForm.SendingAddress === "それ以外"} />それ以外</label></div>
              <div className="err"> {this.props.orderForm.ErrorLabelTexts.SendingAddress} </div>
            </div>
          </div>

          <div className="form_block">
            <div className="form_head">会社名</div>
            <div className="form_body">
              <input type="text" className="" name="CompanyName" value={this.props.orderForm.CompanyName} onChange={this.props.handleInputChange} />
            </div>
          </div>

          <div className="form_block">
            <div className="form_head">
              <span className="form_require">郵便番号</span>
            </div>
            <div className="form_body">
              <div className="form_body_child">
                <input type="text" className="form_size_200" placeholder="例）100-1000" name="PostalCode" value={this.props.orderForm.PostalCode} onChange={this.props.handleInputChange} />
              </div>
              <div className="err"> {this.props.orderForm.ErrorLabelTexts.PostalCode} </div>
            </div>
          </div>

          <div className="form_block">
            <div className="form_head">
              <span className="form_require">ご住所（都道府県）</span>
            </div>
            <div className="form_body">
              <label className="select-group">
                <select name="Prefectural" className="select" value={this.props.orderForm.Prefectural} onChange={this.props.handleInputChange} onBlur={this.props.handleOnBlur}>
                  <option value="">都道府県</option>
                  <option value="北海道">北海道</option>
                  <option value="青森県">青森県</option>
                  <option value="岩手県">岩手県</option>
                  <option value="宮城県">宮城県</option>
                  <option value="秋田県">秋田県</option>
                  <option value="山形県">山形県</option>
                  <option value="福島県">福島県</option>
                  <option value="茨城県">茨城県</option>
                  <option value="栃木県">栃木県</option>
                  <option value="群馬県">群馬県</option>
                  <option value="埼玉県">埼玉県</option>
                  <option value="千葉県">千葉県</option>
                  <option value="東京都">東京都</option>
                  <option value="神奈川県">神奈川県</option>
                  <option value="新潟県">新潟県</option>
                  <option value="富山県">富山県</option>
                  <option value="石川県">石川県</option>
                  <option value="福井県">福井県</option>
                  <option value="山梨県">山梨県</option>
                  <option value="長野県">長野県</option>
                  <option value="岐阜県">岐阜県</option>
                  <option value="静岡県">静岡県</option>
                  <option value="愛知県">愛知県</option>
                  <option value="三重県">三重県</option>
                  <option value="滋賀県">滋賀県</option>
                  <option value="京都府">京都府</option>
                  <option value="大阪府">大阪府</option>
                  <option value="兵庫県">兵庫県</option>
                  <option value="奈良県">奈良県</option>
                  <option value="和歌山県">和歌山県</option>
                  <option value="鳥取県">鳥取県</option>
                  <option value="島根県">島根県</option>
                  <option value="岡山県">岡山県</option>
                  <option value="広島県">広島県</option>
                  <option value="山口県">山口県</option>
                  <option value="徳島県">徳島県</option>
                  <option value="香川県">香川県</option>
                  <option value="愛媛県">愛媛県</option>
                  <option value="高知県">高知県</option>
                  <option value="福岡県">福岡県</option>
                  <option value="佐賀県">佐賀県</option>
                  <option value="長崎県">長崎県</option>
                  <option value="熊本県">熊本県</option>
                  <option value="大分県">大分県</option>
                  <option value="宮崎県">宮崎県</option>
                  <option value="鹿児島県">鹿児島県</option>
                  <option value="沖縄県">沖縄県</option>
                </select>
              </label>
              <div className="err"> {this.props.orderForm.ErrorLabelTexts.Prefectural} </div>
            </div>
          </div>

          <div className="form_block">
            <div className="form_head">
              <span className="form_require">ご住所（市区町村）</span>
            </div>
            <div className="form_body">
              <input type="text" className="" name="Municipalities" value={this.props.orderForm.Municipalities} onChange={this.props.handleInputChange} />
              <div className="err"> {this.props.orderForm.ErrorLabelTexts.Municipalities} </div>
            </div>
          </div>

          <div className="form_block">
            <div className="form_head">
              <span className="form_require">ご住所（番地・建物名）</span>
            </div>
            <div className="form_body">
              <input type="text" className="" name="Address" value={this.props.orderForm.Address} onChange={this.props.handleInputChange} />
              <div className="err"> {this.props.orderForm.ErrorLabelTexts.Address} </div>
            </div>
          </div>

          <div className="form_block">
            <div className="form_head">
              <span className="form_require">電話番号</span>
            </div>
            <div className="form_body">
              <input type="tel" className="" placeholder="例）03-1234-5678" name="TelephoneNumber" value={this.props.orderForm.TelephoneNumber} onChange={this.props.handleInputChange} />
              <div className="err"> {this.props.orderForm.ErrorLabelTexts.TelephoneNumber} </div>
            </div>
          </div>

          <div className="form_block">
            <div className="form_head">
              FAX番号
            </div>
            <div className="form_body">
              <input type="tel" className="" placeholder="例）03-1234-5678" name="FaxNumber" value={this.props.orderForm.FaxNumber} onChange={this.props.handleInputChange} />
            </div>
          </div>

          <div className="form_block">
            <div className="form_head">通信欄</div>
            <div className="form_body">
              <textarea className="textarea_contact" name="Message" value={this.props.orderForm.Message} onChange={this.props.handleInputChange} ></textarea>
            </div>
          </div>

          <div className="form_block">
            <div className="form_head">
              <span className="form_require">支払方法</span>
            </div>
            <div className="form_body">
              <div className="form_item"><label><input type="radio" className="" name="PaymentType" value="郵便振替" onChange={this.props.handleInputChange} checked={this.props.orderForm.PaymentType === "郵便振替"} />郵便振替</label></div>
              <div className="form_item"><label><input type="radio" className="" name="PaymentType" value="銀行振込" onChange={this.props.handleInputChange} checked={this.props.orderForm.PaymentType === "銀行振込"} />銀行振込</label></div>
              <div className="err"> {this.props.orderForm.ErrorLabelTexts.PaymentType} </div>
            </div>
          </div>

          <div className="form_block">
            <div className="form_head"><span className="form_require">ご返信先</span></div>
            <div className="form_body">
              <div className="form_item"><label><input type="radio" className="" name="RepliesAddress" value="電話" onChange={this.props.handleInputChange} checked={this.props.orderForm.RepliesAddress === "電話"} />電話</label></div>
              <div className="form_item"><label><input type="radio" className="" name="RepliesAddress" value="FAX" onChange={this.props.handleInputChange} checked={this.props.orderForm.RepliesAddress === "FAX"} />FAX</label></div>
              <div className="form_item"><label><input type="radio" className="" name="RepliesAddress" value="E-Mail" onChange={this.props.handleInputChange} checked={this.props.orderForm.RepliesAddress === "E-Mail"} />E-Mail</label></div>
              <div className="err"> {this.props.orderForm.ErrorLabelTexts.RepliesAddress} </div>
            </div>
          </div>

          <div className="form_block">
            <div className="form_head">必要書類</div>
            <div className="form_body">
              <div className="mb1em">
                <div className="form_item"><label><input type="checkbox" className="" name="Documents" value="見積書" onChange={this.props.handleInputChangeCheckBox} checked={this.props.orderForm.Documents.some(v => v === "見積書")} />見積書</label></div>
                <div className="form_item"><label><input type="checkbox" className="" name="Documents" value="納品書" onChange={this.props.handleInputChangeCheckBox} checked={this.props.orderForm.Documents.some(v => v === "納品書")} />納品書</label></div>
                <div className="form_item"><label><input type="checkbox" className="" name="Documents" value="請求書" onChange={this.props.handleInputChangeCheckBox} checked={this.props.orderForm.Documents.some(v => v === "請求書")} />請求書</label></div>
              </div>
              <div>
                宛名&nbsp;<input type="text" className="form_size_200" name="NameAndAddress" value={this.props.orderForm.NameAndAddress} onChange={this.props.handleInputChange} />
              </div>
            </div>
          </div>
        </section>
        <section className="contents_block narrow">
          <p>プライバシーポリシーに同意いただける場合は「同意する」にチェックを付け、以下の入力に進んでください。<br />
            ※チェックがない（同意していただけない）場合は入力されても送信出来ません。</p>
          <div className="join_form_privacy">
            <p>
              ＜個人情報保護方針＞<br />
              <br />
              プライバシーポリシーに関して<br />
              <br />
              一般社団法人<br />
              日本鉄道施設協会は、個人情報保護の社会的重要性を強く認識し、その適切な管理を行うことが重要な社会的責務と考え、ご利用の方が安心してご利用いただけるよう運営・管理に努めます。<br />
              <br />
              ■個人情報とは<br />
              当協会が当サイトをご利用される方から提供を受ける住所、氏名、年齢、電話番号、メールアドレス等、個人を識別できる情報または個人固有の情報をいいます。<br />
              <br />
              ■個人情報の収集・取り扱いについて<br />
              当協会が運営するWEBサイト内の一部のページに個人情報のご提供をお願いする場合があります。 収集した個人情報の取り扱いについては、個人情報の重要性を認識し、厳しい管理基準を設け管理を行っています。 収集された個人情報は、提供を受ける際にご了承頂いた利用範囲内での利用を目的とし、第三者への開示は原則として致しません。<br />
              <br />
              ■このプライバシーの考え方の改訂について<br />
              当協会は、当サイトにおけるプライバシーの考え方を改訂することがあります。 重要な変更がある場合には、当サイト上において、分かりやすい方法でお知らせします。<br />
              <br />
              ■管理責任者及びお問合せ窓口について<br />
              当協会では、当サイトにおけるお問合せ窓口を設置しております。 ご質問やご意見がございましたら、各事業所の電話等からお問い合わせください。<br />
              <br />
              一般社団法人 日本鉄道施設協会
            </p>
          </div>
          <div className="aC mb30">
            <div className="form_item"><label><input id="agree_policy" type="checkbox" className="" />同意する</label></div>
          </div>

        </section>
        <div className="aC mb50"><input id="form_submit" type="submit" value="入力内容を確認する" className="btn_rounded" disabled="disabled" /></div>
      </>
    )
  }
}
export default PurchaseForm