import React from 'react'
import { Link, navigate } from 'gatsby'
import BooksInCart from '../components/booksInCart'
import PurchaseForm from '../components/Forms/purchaseForm'
import { getCart, setItemCount, removeCart } from '../services/cart'
import $ from 'jquery'
import Seo from "../components/seo"
import { sessionKeys, errorMessageTemplates } from "../services/const"
import { getFormValues, setFormValues, removeFormValues } from '../services/form'
import { isLoggedIn, getUser } from "../services/auth"

const namepair = [{ "Documents": "Document" }]
const displayLabels = {
  LastNameKanji: { Label: "お名前", Property: "NameKanji" },
  FirstNameKanji: { Label: "お名前", Property: "NameKanji" },
  LastNameKatakana: { Label: "お名前（フリガナ）", Property: "NameKatakana" },
  FirstNameKatakana: { Label: "お名前（フリガナ）", Property: "NameKatakana" },
  MailAddress: { Label: "メールアドレス" },
  MailAddressConfirmation: { Label: "メールアドレス" },
  SendingAddress: { Label: "書類の送付先" },
  PostalCode: { Label: "郵便番号" },
  Prefectural: { Label: "都道府県" },
  Municipalities: { Label: "ご住所（市区町村）" },
  Address: { Label: "ご住所（番地・建物名）" },
  TelephoneNumber: { Label: "電話番号" },
  PaymentType: { Label: "支払い方法" },
  RepliesAddress: { Label: "ご返信先" }
}

class OrderForm extends React.Component {
  state = {
    OrderItems: [],
    FullPrice: "",
    MemberNumber: "-",
    LastNameKanji: "",
    FirstNameKanji: "",
    LastNameKatakana: "",
    FirstNameKatakana: "",
    MailAddress: "",
    MailAddressConfirmation: "",
    SendingAddress: "",
    CompanyName: "",
    PostalCode: "",
    Prefectural: "",
    Municipalities: "",
    Address: "",
    TelephoneNumber: "",
    FaxNumber: "",
    Message: "",
    PaymentType: "",
    RepliesAddress: "",
    Documents: [],
    NameAndAddress: "",
    ErrorLabelTexts: {},
    Cart: []
  }

  isValidInput = () => {
    return true;
  }

  getErrorLabelTexts = (state) => {
    const errorLabelTexts = {}
    const requires = ["LastNameKanji", "FirstNameKanji", "LastNameKatakana", "FirstNameKatakana", "MailAddress", "MailAddressConfirmation",
      "SendingAddress", "PostalCode", "Prefectural", "Municipalities", "Address", "TelephoneNumber", "PaymentType", "RepliesAddress"]
    requires.forEach(v => {
      if (Array.isArray(state[v])) {
        if (state[v].length === 0) {
          errorLabelTexts[displayLabels[v].Property === void 0 ? v : displayLabels[v].Property] = displayLabels[v].Label + errorMessageTemplates.noInput
        }
      } else if (state[v] === null ||
        state[v] === "" ||
        state[v] === void 0 ||
        state[v].match(/^[ 　\r\n\t]*$/)) {
        errorLabelTexts[displayLabels[v].Property === void 0 ? v : displayLabels[v].Property] = displayLabels[v].Label + errorMessageTemplates.noInput

      } else if (v === "MailAddress" || v === "MailAddressConfirmation") {
        if (!state[v].match(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}.[A-Za-z0-9]{1,}$/)) {
          errorLabelTexts[displayLabels[v].Property === void 0 ? v : displayLabels[v].Property] = displayLabels[v].Label + errorMessageTemplates.typo
        }
      } else if (v === "PostalCode") {
        if (!state[v].match(/^[0-9]{3}-[0-9]{4}$/)) {
          errorLabelTexts[displayLabels[v].Property === void 0 ? v : displayLabels[v].Property] = displayLabels[v].Label + errorMessageTemplates.typo
        }
      } else if (v === "TelephoneNumber") {
        var tel = state[v].replace(/[━.*‐.*―.*－.*\-.*ー]/gi, '');
        if (!tel.match(/^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/)) {
          errorLabelTexts[displayLabels[v].Property === void 0 ? v : displayLabels[v].Property] = displayLabels[v].Label + errorMessageTemplates.typo
        }
      }
    })

    if (state["MailAddress"] !== state["MailAddressConfirmation"]) {
      errorLabelTexts["MailAddressInconsistency"] = displayLabels.MailAddressConfirmation.Label + errorMessageTemplates.inconsistency
    }

    return errorLabelTexts
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  handleInputChangeCheckBox = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    const list = this.state[name]
    if (target.checked) {
      list.push(value)
    }
    else {
      const index = list.indexOf(value)
      list.splice(index, 1)
    }
    this.setState(
      {
        [name]: list,
      }
    )
  }

  handleSetItemCount = (itemId, event) => {
    event.preventDefault()

    const target = event.target
    const items = this.state.Cart.map(element => {
      if (element.id === itemId) {
        element.count = target.value
      }
      return element
    })

    this.setCartState(items)
  }

  handleRemoveItem = (itemid) => {
    const cart = this.state.Cart
    const deleteIndex = cart.findIndex(({ id }) => id === itemid)
    cart.splice(deleteIndex, 1)

    this.setCartState(cart)
    removeCart(itemid)
  }

  handleOnBlur = event => {
  }

  handleSubmit = event => {
    event.preventDefault()

    const errorMessages = this.getErrorLabelTexts(this.state)
    if (Object.keys(errorMessages).length > 0) {
      this.setState({
        ErrorLabelTexts: errorMessages
      })
      navigate('#')
    }
    else {
      namepair.forEach((np, index) => {
        for (const [key, value] of Object.entries(np)) {
          const forMailValue = this.state[key].map(x => {
            return { [value]: x }
          })
          this.setState({
            [key + "ForMail"]: forMailValue,
          }, () => {
            this.state.Cart.forEach(v => setItemCount(v.id, v.count))
            setFormValues(sessionKeys.orderForm, this.state)
            if (namepair.length - 1 === index) {
              navigate('/orderConfirm')
            }
          })
        }
      })
    }
  }

  setCartState = (cartItems) => {

    const orderItems = cartItems.map((v, index) => {
      const price = isLoggedIn() ? v.memberprice : v.price
      const item = { "Index": index + 1, "Title": v.title, "Price": price.toLocaleString(), "Count": v.count }
      return item
    })
    const fullPrice = cartItems.reduce((prev, current) => prev + (isLoggedIn() ? current.memberprice : current.price) * current.count, 0)

    this.setState({
      Cart: cartItems
    })
    this.setState({
      OrderItems: orderItems
    })
    this.setState({
      FullPrice: fullPrice.toLocaleString()
    })
  }

  componentDidMount = () => {
    $("#agree_policy").on("click", function () {
      if ($(this).prop("checked") === true) {
        $("#form_submit").prop("disabled", false);
      } else {
        $("#form_submit").prop("disabled", true);
      }
    });

    const restoreValue = getFormValues(sessionKeys.orderForm, "ErrorLabelTexts")
    Object.keys(restoreValue).forEach(k => {
      this.setState({
        [k]: restoreValue[k],
      })
    })

    if (isLoggedIn()) {
      this.setState({
        MemberNumber: getUser().MemberNo
      })
    }

    const cart = getCart()
    this.setCartState(cart)
  }

  render = () => {
    if (typeof window === "undefined") {
      return null
    }

    if (getCart().length === 0) {
      removeFormValues(sessionKeys.orderForm);
      navigate('/orderFormEmpty')
      return null
    }

    return (
      <>
        <Seo title="出版・刊行物 ご注文手続き" />
        <div id="pageTitle">
          <h1>
            出版・刊行物 ご注文手続き
          </h1>
        </div>
        <div className="breadClumb">
          <ul>
            <li><Link to="/">HOME</Link></li>
            <li><Link to="/publication">出版・刊行物</Link></li>
            <li>出版・刊行物 ご注文手続き</li>
          </ul>
        </div>
        <div className="main">
          <div className="contents">
            <BooksInCart cart={this.state.Cart} handleSetItemCount={this.handleSetItemCount} handleRemoveItem={this.handleRemoveItem} handleOnBlur={this.handleOnBlur}></BooksInCart>
            <form name="" onSubmit={this.handleSubmit}>
              <PurchaseForm orderForm={this.state} handleInputChange={this.handleInputChange} handleInputChangeCheckBox={this.handleInputChangeCheckBox} handleOnBlur={this.handleOnBlur}></PurchaseForm>
            </form>
          </div>
        </div>
      </>
    )
  }
}

export default OrderForm