import React from 'react'
import { Link } from 'gatsby'
import { isLoggedIn } from '../services/auth'

class BooksInCart extends React.Component {

  render = () => {
    const { cart, handleSetItemCount, handleRemoveItem, handleOnBlur } = this.props
    if (!Array.isArray(cart)) {
      return null
    }

    const books = cart;
    const bookElements = books.map(book => {
      const { id, title, publicUrl, memberprice, price, count } = book
      const selectItems = [...Array(30).keys()].map(i => ++i).map(num => {
        return (<option key={num} value={num}>{num}</option>)
      })

      return (
        <li key={book.id}>
          <div className="book_img"><img src={publicUrl} alt="" className="imgFit" /></div>
          <div className="book_info">
            <div className="book_title">
              {title}
            </div>
            <div className="book_price">
              価格：{isLoggedIn() ?
                String(memberprice).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') :
                String(price).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}円（税込）
            </div>
            <div className="book_qty">
              お申込み冊数&nbsp;
              <select name="" className="select-group" defaultValue={count} onChange={(e) => handleSetItemCount(id, e)} onBlur={handleOnBlur}>
                {selectItems}
              </select>冊
            </div>
            <div className="book_delete">
              <button onClick={(e) => handleRemoveItem(id)}>削除</button>
            </div>
          </div>
        </li>
      )
    })

    return (
      <section className="contents_block">
        <h2 className="header01">ご注文内容</h2>
        <div className="inner">
          <p className="mb50">お申し込み冊数を選択してください。<br />
            各書籍は一種類につき30冊までご注文いただけます。<br />
            それ以上ご注文される場合は、繰り返しご注文いただくか<Link to="/contact" className="linkTxt">協会までお問い合わせください</Link>。</p>
          <ul className="book_cartList">
            {bookElements}
          </ul>
        </div>
      </section>
    )
  }
}

export default BooksInCart


